/* eslint new-cap: "off", no-param-reassign: "off" */
/* globals GlobalE, Promise */
'use strict';

//--------------------------------------------------
// Helpers
//--------------------------------------------------

var Helpers = {
    makeJsonAjaxCall: function (url, type, data) {
        var promise = new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open(type, url, true);
            xhr.setRequestHeader('Cache-Control', 'no-cache');
            xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            xhr.send(data || null);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(xhr.responseText);
                    } else {
                        reject(xhr.status);
                    }
                }
            };
        });
        return promise;
    },
    removeParametersFromQueryString: function (queryString, parameters) {
        var qsParams = queryString && (typeof queryString === 'string') && queryString.length > 0 ? queryString.split('&') : [];
        var removedParams = parameters && Array.isArray(parameters) && parameters.length > 0 ? parameters : [];
        removedParams.forEach(function (param) {
            var prefix = encodeURIComponent(param) + '=';
            for (var i = qsParams.length; i-- > 0;) {
                if (qsParams[i].lastIndexOf(prefix, 0) !== -1) {
                    qsParams.splice(i, 1);
                }
            }
        });
        return qsParams.join('&');
    }
};

//--------------------------------------------------
// Globale Script Loader
//--------------------------------------------------

/**
 * Represents Globale Script Loader Class
 * @constructor
 */
function GeScriptLoader() {}

/**
 * Init Globale Script Loader Data
 */
GeScriptLoader.prototype.initScriptLoaderData = function () {
    try {
        window.geScriptLoaderData = JSON.parse(document.querySelector('#globale-script-loader-data').innerHTML);
    } catch (e) {
        window.geScriptLoaderData = null;
        console.warn(e); // eslint-disable-line no-console
    }
};

/**
 * Init Global-e Client SDK
 * @returns {Object} promise - Promise Object
 */
GeScriptLoader.prototype.initClientSDK = function () {
    // check script loader data
    if (!window.geScriptLoaderData) {
        return Promise.reject();
    }

    return new Promise(function (resolve, reject) {
        window.globaleObject = 'gle';
        window.gle = window.gle || function () { (window.gle.q = window.gle.q || []).push(arguments); };
        window.gle.m = window.geScriptLoaderData.clientJsMerchantId;
        window.gle.v = window.geScriptLoaderData.apiVersion;
        var s = document.createElement('script');
        s.src = window.geScriptLoaderData.clientJsUrl + '?v=' + window.geScriptLoaderData.apiVersion;
        s.async = true;
        s.onerror = function (err) {
            reject(err, s);
        };
        var r = false;
        s.onload = s.onreadystatechange = function () {
            if (!r && (!this.readyState || this.readyState === 'complete')) {
                r = true;
                resolve();
            }
        };
        var t = document.querySelectorAll('script')[0];
        t.parentElement.insertBefore(s, t);
    });
};

/**
 * Init Content Price Conversion
 */
GeScriptLoader.prototype.initContentPriceConversion = function () {
    /**
     * Run Price Conversion handler
     */
    function runPriceConversion() {
        // find DOM price elements
        var priceElements = document.querySelectorAll('[data-ge-convert-price]');

        // set request data
        var requestData = {};
        priceElements.forEach(function (el) {
            // skip converted item
            // or if is not configured at least one mandatory attribute('data-original-price', 'data-product-id') for conversion
            if (el.dataset.isConverted === 'true' || (!el.dataset.originalPrice && !el.dataset.productId)) { // skip converted item
                return;
            }

            var key = (el.dataset.productId ? el.dataset.productId + '_' : '') + (el.dataset.originalPrice ? el.dataset.originalPrice + '_' + (el.dataset.isDiscount === 'true') : '');
            if (el.dataset.productId) { // the highest priority is to process 'data-product-id'
                if (!(key in requestData)) {
                    requestData[key] = {
                        productId: String(el.dataset.productId)
                    };
                }
            } else if (el.dataset.originalPrice) { // process 'data-original-price' and 'data-is-discount'
                if (!(key in requestData)) {
                    requestData[key] = {
                        originalPrice: Number(el.dataset.originalPrice),
                        isDiscount: el.dataset.isDiscount === 'true'
                    };
                }
            }
        });

        // send the request and update DOM price elements
        if (Object.keys(requestData).length > 0) {
            Helpers.makeJsonAjaxCall(window.geScriptLoaderData.globaleConvertPriceUrl, 'POST', JSON.stringify(requestData))
                .then(function (response) {
                    if (response) {
                        response = JSON.parse(response);
                        priceElements.forEach(function (el) {
                            var key = (el.dataset.productId ? el.dataset.productId + '_' : '') + (el.dataset.originalPrice ? el.dataset.originalPrice + '_' + (el.dataset.isDiscount === 'true') : '');
                            if (key in response) {
                                el.innerHTML = response[key]; // eslint-disable-line no-param-reassign
                                el.dataset.isConverted = 'true';
                            }
                        });
                    }
                })
                .catch(function (e) {
                    console.warn(e);  // eslint-disable-line no-console
                });
        }
    }

    try {
        // check script loader data
        if (!window.geScriptLoaderData || !window.geScriptLoaderData.globaleConvertPriceUrl) {
            return;
        }

        /**
         * Add event listener
         * This custom event can be triggered if is needed to update/re-calculate the values in static content
         * @example
         * // Create the event
         * var event = new CustomEvent("runPriceConversion");
         * // Trigger the event
         * window.dispatchEvent(event);
         */
        window.addEventListener('runPriceConversion', runPriceConversion, false);

        // run initial price conversion
        runPriceConversion();
    } catch (e) {
        console.warn(e);  // eslint-disable-line no-console
    }
};

/**
 * Remove Global-e redirect parameters
 */
GeScriptLoader.prototype.removeGeRedirectParams = function () {
    try {
        var updatedUrl = new URL(document.location);
        var urlParams = updatedUrl.searchParams;

        if (urlParams.get('glCountry') || urlParams.get('glCurrency')) {
            // set 'Welcome Popup' cookie with 'false' value
            // additional GE parameters are existed in URL only during redirect and 'Welcome Popup' should not be displayed
            GlobalE.SetCookie('GlobalE_Welcome_Data', { showWelcome: false }, GlobalE.GE_DATA_COOKIE_EXP, true);

            // remove GE parameters and relpace history state
            urlParams.delete('glCountry');
            urlParams.delete('glCurrency');
            window.history.replaceState({}, document.title, updatedUrl.toString());

            // remove GE parameters from 'data-querystring' in DOM
            var requestDataEl = document.querySelector('[data-action][data-querystring]');
            if (requestDataEl) {
                var queryString = requestDataEl.getAttribute('data-querystring');
                queryString = Helpers.removeParametersFromQueryString(queryString, ['glCountry', 'glCurrency']);
                requestDataEl.setAttribute('data-querystring', queryString);
            }
        }
    } catch (e) {
        console.warn(e); // eslint-disable-line no-console
    }
};

/**
 * Handler function of 'GlobalE.LoadShippingSwitcher' event
 */
GeScriptLoader.prototype.loadShippingSwitcherHandler = function () {
    try {
        var links = document.querySelectorAll('.globale-selector');
        Array.prototype.map.call(links, function (link) {
            GlobalE.AttachEvent('click', link, function () {
                return GlobalE.ShippingSwitcher.Show();
            });
        });
        // show country switcher popup in blocking mode
        if (!window.geScriptLoaderData.allowedSite) {
            GlobalE.SetCookie('GlobalE_Welcome_Data', { showWelcome: false }, GlobalE.GE_DATA_COOKIE_EXP, true);
            GlobalE.ShippingSwitcher.ForceModal();
            GlobalE.ShippingSwitcher.Show();
        }
    } catch (e) {
        console.warn(e); // eslint-disable-line no-console
    }
};

/**
 * Handler function of 'GlobalE.OnShippingSwitcherClosed' event
 * @param {Object} event - Event object
 * @returns {boolean} - 'false' by default
 */
GeScriptLoader.prototype.onShippingSwitcherClosedHandler = function (event) {
    try {
        if (
            (event.button === 'save')
            && (
                !window.geScriptLoaderData.allowedSite
                || (event.country !== window.geScriptLoaderData.country)
                || (event.currency !== window.geScriptLoaderData.currency)
                || (
                    GlobalE.ShippingSwitcher.isLanguageDropDownShown &&
                    GlobalE.ShippingSwitcher.selectedLanguage !== window.geScriptLoaderData.languageSwitcher.selectedLanguage
                )
            )
        ) {
            if (!event.isRedirect) {
                GlobalE.UpdateCustomerInfo(event.country, event.currency, false);
                window.location.reload();
            } else if (window.geScriptLoaderData.performFrontendSiteUrlRedirect) {
                var getSiteRedirectUrl = window.geScriptLoaderData.getSiteRedirectUrl;
                getSiteRedirectUrl += '?countryCode=' + encodeURIComponent(event.country);
                getSiteRedirectUrl += '&currencyCode=' + encodeURIComponent(event.currency);
                if (GlobalE.ShippingSwitcher.isLanguageDropDownShown) {
                    getSiteRedirectUrl += '&localeCode=' + encodeURIComponent(GlobalE.ShippingSwitcher.selectedLanguage);
                }
                var requestDataEl = document.querySelector('[data-action][data-querystring]');
                if (requestDataEl) {
                    getSiteRedirectUrl += '&action=' + encodeURIComponent(requestDataEl.dataset.action);
                    getSiteRedirectUrl += '&querystring=' + encodeURIComponent(requestDataEl.dataset.querystring);
                }
                Helpers.makeJsonAjaxCall(getSiteRedirectUrl, 'GET')
                    .then(function (response) {
                        if (response) {
                            response = JSON.parse(response);
                            if (window.GlobalE && GlobalE.onGetSiteRedirectUrlAjaxCompleted) {
                                GlobalE.onGetSiteRedirectUrlAjaxCompleted(event, response);
                            }
                            if (!response.success) {
                                window.location.reload();
                            } else if (response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                        }
                    })
                    .catch(function (e) {
                        console.warn(e); // eslint-disable-line no-console
                    });
            }
        }
    } catch (e) {
        console.warn(e); // eslint-disable-line no-console
    }
    return false;
};

/**
 * Init 'KeepAlive' feature
 */
GeScriptLoader.prototype.initKeepAlive = function () {
    var geIframe = document.querySelector('#gle_iframe');
    try {
        window.globaleKeepAlive = setInterval(function () {
            Helpers.makeJsonAjaxCall(geIframe.dataset.keepaliveUrl, 'GET')
                .then(function (response) { // eslint-disable-line no-unused-vars
                    // handle response
                })
                .catch(function (e) {
                    console.warn(e); // eslint-disable-line no-console
                });
        }, 60000); // every 1 minute
    } catch (e) {
        console.warn(e); // eslint-disable-line no-console
    }
};

/**
 * Handler function of 'GlobalE.OnCheckoutStepLoadedHandler' event
 * @param {Object} data - Event data
 */
GeScriptLoader.prototype.onCheckoutStepLoadedHandler = function (data) {
    try {
        if (data.StepId === data.Steps.CONFIRMATION) {
            // stop sending keepAlive ajax call
            if (('globaleKeepAlive' in window) && window.globaleKeepAlive) {
                clearInterval(window.globaleKeepAlive);
            }
        }

        /**
         * If you need to implement the frontend analytics please put it here
         */
        try {
            if (data.StepId === data.Steps.CONFIRMATION) {
                var digitalData = {};
                var ecommerce = {};
                var couponCode = '';
                var productLineItems = data.details.ProductInformation;
                if (data.details.Discounts.length !== 0) {
                    data.details.Discounts.forEach(function (discount) {
                        couponCode = discount.CouponCode ? discount.CouponCode : couponCode;
                    });
                }
    
                ecommerce.currency = data.details.MerchantCurrencyCode;
                ecommerce.transaction_id = data.details.OrderID;
                ecommerce.affiliation = 'Online Store';
                ecommerce.value = data.details.OrderPrices.MerchantTransaction.TotalDiscountedProductsPrice;
                ecommerce.tax = data.details.OrderPrices.MerchantTransaction.TotalVAT;
                ecommerce.shipping = '';
                ecommerce.coupon = couponCode;
                ecommerce.checkout_step = '';
                ecommerce.checkout_option = '';
                ecommerce.items = getLineItems(productLineItems);
    
                digitalData.event = 'purchase';
                digitalData.ecommerce = ecommerce;
                window.dataLayer.push(digitalData);
            }
        } catch (error) {
            window.console.error('\ngeScriptLoader.js: error in {0}', error);
        }

        /**
         * Only for Backend Analytics, like standard SFCC Merchant Tools / Analytics / Purchase Reports.
         * Should be handled in Globale-Analytics controller
         */
        var geIframe = document.querySelector('#gle_iframe');
        Helpers.makeJsonAjaxCall(geIframe.dataset.analyticsUrl, 'POST', JSON.stringify(data))
            .then(function (response) { // eslint-disable-line no-unused-vars
                // handle response
            })
            .catch(function (e) {
                console.warn(e); // eslint-disable-line no-console
            });
    } catch (e) {
        console.warn(e); // eslint-disable-line no-console
    }
};

/**
 * Handler function of 'GlobalE.OnClientEvent' event
 * Used for Extreme Analytic
 * @param {string} source - Event source
 * @param {Object} data - Event data
 */
GeScriptLoader.prototype.onClientEvent = function (source, data) { // eslint-disable-line no-unused-vars
    try {
        switch (source) {
            case GEMerchantUtils.ClientEvents.INPUT_BLUR: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.STORE_SELECTION: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.SHIPPINGMETHOD_SELECTION: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.TAX_OPTION_SELECTED: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.PAYMENTMETHOD_CHANGED: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.BUTTON_CLICKED: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.COMBO_CHANGED: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.ADD_NEW_SHIPPING_ADDRESS: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.ON_PAGE_LOAD: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.ON_CLIENT_ERROR: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.ON_SERVER_ERROR: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.CHECKBOX_CHANGE: // eslint-disable-line no-undef
                // your code
                break;
            case GEMerchantUtils.ClientEvents.WINDOW_SHOWN: // eslint-disable-line no-undef
                // your code
                break;
            default:
                break;
        }
    } catch (e) {
        console.warn(e); // eslint-disable-line no-console
    }
};

/**
 * Customer registration post message handler
 * @param {Object} event - Event object
 */
GeScriptLoader.prototype.registrationPostMessageHandler = function (event) {
    try {
        // check request is from legitimate source and message is expected or not
        if (!GlobalE || !GlobalE.CheckoutManager || !GlobalE.CheckoutManager.IsGlobaleMessage(event.origin)) { return; }
        var eventMessage = JSON.parse(event.data);
        var geIframe = document.querySelector('#gle_iframe');
        if (('Key' in eventMessage) && eventMessage.Key === 'CustomerRegistration') {
            Helpers.makeJsonAjaxCall(geIframe.dataset.registrationUrl, 'POST', JSON.stringify(eventMessage.Data))
                .then(function (response) {
                    if (response) {
                        response = JSON.parse(response);
                        GlobalE.CheckoutManager.UpdateGlobalE('CustomerRegistration', response);
                    }
                })
                .catch(function (e) {
                    console.warn(e); // eslint-disable-line no-console
                });
        }
    } catch (e) {
        console.warn(e); // eslint-disable-line no-console
    }
};

/**
 * Init events
 */
GeScriptLoader.prototype.initEvents = function () {
    // check script loader data
    if (!window.geScriptLoaderData) {
        return;
    }

    // ---------------------------GENERAL EVENTS--------------------------- //
    // 'GlobalE.SetMerchantParameters' event
    GlobalE.SetMerchantParameters(JSON.parse(window.geScriptLoaderData.clientSettings));

    // 'GlobalE.ScriptsURL' event
    GlobalE.ScriptsURL(window.geScriptLoaderData.clientJsDomain);

    // 'GlobalE.SetCookieDomain' event
    GlobalE.SetCookieDomain(window.geScriptLoaderData.cookieDomain);

    // Remove Global-e redirect parameters
    this.removeGeRedirectParams();

    // 'GlobalE.LoadWelcome' event
    if (window.geScriptLoaderData.globaleOperatedCountry && window.geScriptLoaderData.allowedSite) {
        GlobalE.LoadWelcome(window.geScriptLoaderData.country, window.geScriptLoaderData.culture, window.geScriptLoaderData.currency);
    }

    // 'GlobalE.LoadShippingSwitcher' event
    GlobalE.LoadShippingSwitcher(window.geScriptLoaderData.country, window.geScriptLoaderData.culture, window.geScriptLoaderData.currency, false, this.loadShippingSwitcherHandler);

    // 'GlobalE.OnShippingSwitcherClosed' event
    GlobalE.OnShippingSwitcherClosed(this.onShippingSwitcherClosedHandler);

    // ---------------------------CHECKOUT EVENTS--------------------------- //
    var geIframe = document.querySelector('#gle_iframe');
    if (geIframe) {
        // 'GlobalE.Checkout' event
        GlobalE.Checkout(geIframe.dataset.cartToken, 'gle_iframe');

        // 'KeepAlive' feature
        this.initKeepAlive();

        // 'GlobalE.OnCheckoutStepLoaded' event
        GlobalE.OnCheckoutStepLoaded(this.onCheckoutStepLoadedHandler);

        // 'GlobalE.OnClientEvent' event
        GlobalE.OnClientEvent(this.onClientEvent);

        // 'Customer registration' feature
        window.addEventListener('message', this.registrationPostMessageHandler, false);
    }
};

GeScriptLoader.prototype.init = function () {
    this.initScriptLoaderData();
    this.initContentPriceConversion();
    this.initClientSDK()
        .then(this.initEvents.bind(this))
        .catch(function (e) {
            console.warn(e); // eslint-disable-line no-console
        });
};

//--------------------------------------------------
// Initialisation
//--------------------------------------------------

/**
 * General init function
 */
function init() {
    var geScriptLoader = new GeScriptLoader();
    geScriptLoader.init();
}

/**
 * Run initialisation once document is loaded
 */
if (/complete|interactive|loaded/.test(document.readyState)) {
    init();
} else {
    document.addEventListener('DOMContentLoaded', init);
}

/**
 * Create array of obejct
 * @param {Object} pli
 * @return {Array} 
 */
function getLineItems(pli) {
    // Add all product line items to request
    var lineItems = [];
    pli.forEach(function (lineItem) {
        var lineItemObject = {};
        lineItemObject.item_name = lineItem.ProductName ? lineItem.ProductName : '';
        lineItemObject.item_id = lineItem.SKU ? lineItem.SKU : '';
        lineItemObject.affiliation = 'Online Store';
        lineItemObject.quantity = lineItem.Quantity ? lineItem.Quantity : '';
        lineItemObject.price = lineItem.ProductPrices.MerchantTransaction.DiscountedPrice * lineItem.Quantity;
        lineItemObject.item_category = '';
        lineItemObject.item_category2 = '';
        lineItemObject.item_category3 = '';
        lineItemObject.item_category4 = '';
        lineItemObject.item_category5 = '';
        lineItemObject.coupon = '';
        lineItemObject.discount = lineItem.ProductPrices.MerchantTransaction.DiscountedPrice;
        lineItemObject.brand = ''; //product.product.custom.keyStory ? product.product.custom.keyStory : '';
        lineItemObject.variant = ''; //product.product.custom.department ? product.product.custom.department : '';

        lineItems.push(lineItemObject);
    });

    return lineItems;
}
